<template>
  <div
    class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 gap-4"
  >
    <div class="max-w-sm rounded-2xl overflow-hidden shadow-lg mx-2 flex flex-col justify-between gap-y-3" v-for="(order, key) in localOrders" :key="key">
      <div class="font-bold mb-1 bg-green-500 py-3 px-4 flex justify-between items-center gap-5">
        <div class="flex px-3 flex-col text-left justify-baseline">
          <p class="text-gray-800 font-medium">#{{ order.code }}</p>
          <p class="text-gray-700 text-lg font-extralight">{{ getTimeDiff(order.created_at) }}</p>
        </div>
        <div>
          <img 
            class="h-6 w-6 cursor-pointer" 
            src="@/assets/images/cancel.png" 
            alt="Close"
            @click="cancel(order.id)"
          >
        </div>
      </div>
      <div class="px-4 py-2 shadow-sm" v-for="(product, index) in order.orderProducts" :key="index">
        <div class="border-b-1 border-gray-300 mb-1">
            <p class="text-left text-xl font-bold">{{ product.quantity }}x  {{ product.product_name }}</p>
        </div>
        <list-component :addons="product.addons"/>
      </div>
      <slot :order="order"></slot>
    </div>
  </div>
</template>

<script>
import ListComponent from './ListComponent.vue';
import { mapActions } from 'vuex';
import { cancelOrder } from '../api/orders';

export default {
  props: ["orders"],  // Orders passed as a prop
  components: {
    ListComponent,
  },
  data() {
    return {
      localOrders: [],  // Local orders array initialized empty
      branch: this.$store.getters['auth/branch'],  // Branch info from Vuex store
      loading: false,  // For loading state
    };
  },
  computed: {
    ordersData() {
      return this.orders
    }
  },
  created() {
    window.Echo.channel("order-cancelled-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.localOrders = this.localOrders.filter((order) => order.id !== data.id);
      }
    );

    setInterval(() => {
      this.updateTimeDifference();
    }, 1000);
  },
  
  watch: {
    // Watch for changes in the `orders` prop and update `localOrders`
    orders: {
      handler(newOrders) {
        this.localOrders = [...newOrders];
      },
      immediate: true,
      deep: true  // Ensure the watcher runs when the component is created
    },
  },
  methods: {
    ...mapActions("global", ["showError", "showSuccess"]),  // Vuex global actions for notifications

    // Method to handle order cancellation
    cancel(id) {
      this.loading = true;
      cancelOrder({ id: id })
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.showSuccess(response.message);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },

    getTimeDiff(dateTime) {
      const orderTime = new Date(dateTime).getTime();
      const now = new Date().getTime();
      let seconds = Math.floor((now - orderTime) / 1000);
      /*
      * Display hours and minutes
      */
     var minutes = ""

      let interval = seconds / 3600;
      if (interval > 1) {
        var hours = Math.floor(interval);
        minutes = Math.floor((interval % 1) * 60);
        if(hours < 10) {
          hours = `0${hours}`
        } 
        if(minutes < 10) {
          minutes = `0${minutes}`
        }
        return `${hours} : ${minutes}`;
      }

      /*
      * Display minutes and seconds
      */
      interval = seconds / 60;
      if (interval > 1) {
        minutes = Math.floor(interval);
        var sec = Math.floor(interval % 1 * 60);
        if(minutes < 10) {
          minutes = `0${minutes}`
        } 
        if(sec < 10) {
          sec = `0${sec}`
        }
        return `${minutes} : ${sec}`;
      }
      
      /**
       * displays seconds
       */
      return `00:${seconds}`;
    },
    /**
     * updates time difference every second
     */
     updateTimeDifference() {
      this.ordersData.forEach((order, index) => {
        this.ordersData[index] = { ...this.ordersData[index], created_at: order.created_at };
      });
    },
  },
};
</script>
