<template>
  <li
    class="text-gray-700 mx-3 border-b-2 list-none flex items-center"
    v-for="(addon, i) in localAddons"
    :key="i"
  >
    <p class="px-2 text-left text-2xl flex-1">
      <small class="text-xl font-medium text-gray-700">
        <span>{{ addon.quantity }}x {{ addon.product_name }}</span>
      </small>
    </p>
  </li>
</template>
  
  <script>
export default {
  props: ["addons"],
  data() {
    return {
      // Create a local copy of the addons prop and add the checked property
      localAddons: this.addons.map((addon) => ({
        ...addon,
        checked: false, // Add a checked property to track checkbox state
      })),
    };
  },
};
</script>
  <style scoped>
.form-checked {
  border-radius: 40px;
}
</style>