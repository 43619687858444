<template>
  <VueSpinnerHourglass
    size="100"
    color="blue"
    :class="'absolute top-50 left-50 hourgalss z-50'"
    v-show="loading"
  />
  <div
    class="flex flex-col items-center bg-[#0d3c4e] justify-center h-screen"
    :class="loading ? 'd-orders' : ''"
  >
  <form class="bg-[#F4EADE] rounded-xl shadow-md px-7 py-10  mb-4 xl:w-[22%]"  >
          <div class="flex justify-center items-center">
      <img class="w-56 pt-5" src="../assets/harveysLogo.jpeg" alt="">
           </div>
           <p class="login mb-7 mt-2 text- text-3xl">Kitchen(login)</p>

            <div class="mb-3 text-left px-4">
              <label for="email" class="text-2xl ">Email</label>
              <br>
              <input
                type="email"
                v-model="email"
                class="form-control w-full p-2 text-gray-800 rounded-md text-lg "
                name="email"
                placeholder="xyz@gmail.com"
                required
              />
            </div>
            <div class=" mb-10 text-left px-4">
              <label for="password" class=" text-2xl ">Password</label>
              <br>
              <input
                type="password"
                v-model="password"
                class="form-control w-full p-2 text-gray-800 rounded-md text-lg"
                name="password"
                placeholder="Password"
                required
              />
            </div>
    <div class="flex items-center justify-center px-4  ">
      <button class="w-full bg-[#0d3c4e] text-white text-xl  py-2 rounded" type="button" @click="submit">
        Login
      </button>
    </div>
  </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { loginUser } from '../api/auth';
import { VueSpinnerHourglass } from 'vue3-spinners';
import './../assets/login.css';

export default {
  components: {
    VueSpinnerHourglass,
  },
  data() {
    return {
      email: '',
      password: '',
      signedIn: 0,
      loading: false,
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    ...mapActions('global', ['showError', 'showSuccess']),

    submit() {
      this.loading = true;
      loginUser({ email: this.email, password: this.password, type: 'kds' })
        .then((response) => {
          if (response.status) {
            this.loginUser(response.data);
            setTimeout(() => {
              this.loading = false;
              this.showSuccess(response.message);
              window.location.href = '/';
            }, 2000);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
  },
};
</script>
<style scoped>
.bg-custom {
  background-image: url(../assets/images/frame.jpg);
  background-repeat: no-repeat;
 
}
.form-control{
  background-color: #cad8db !important;
}
</style>