<template>
  <SideBar>
  <VueSpinnerHourglass size="100" color="blue" :class="'absolute top-50 left-50 hourgalss z-50'" v-show="loading"/>
    <div
      class="fixed bottom-5 right-5 clear-both bg-[#F4EADE] sm:w-2/6 md:1/6 lg:w-60 p-3 opacity-50"
    >
      <p class="px-4 text-left m-0 text-[#0F3442] text-sm leading-3">
        Display Pending Orders
      </p>
      <p class="text-left text-gray-500 text-sm leading-5">
        <small class="px-4 text-left m-0"
          >show all orders in pending state</small
        >
      </p>
    </div>
    <order-card class="mt-3" :class="loading ? 'd-orders' : ''" :orders="orders" v-slot="{ order }">
      <div class="flex justify-center px-6 pt-4 pb-2">
        <!-- <span
          @click="cancel(order.id)"
          class="cursor-pointer bg-red-300 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2"
          >Cancel</span
        > -->
        <span
          @click="ready(order.id)"
          class="cursor-pointer inline-block bg-transparent border border-gray-900 rounded-3xl px-16 py-1 text-lg font-light text-gray-600 mr-2 mb-2"
          >Complete</span
        >
      </div>
    </order-card>
    <audio ref="audio" src="/Ding.mp3" type="audio/mpeg" preload id="audio"></audio>
  </SideBar>
</template>
<script>
import SideBar from "./SideBar.vue";
import OrderCard from "../components/OrderCard.vue";
import { mapActions } from "vuex";
import { getPendingOrders, readyOrder, cancelOrder, getSettings } from '../api/orders';
import {VueSpinnerHourglass} from 'vue3-spinners';
export default {
  components: {
    OrderCard,
    SideBar,
    VueSpinnerHourglass
  },
  created() {
    window.Echo.channel("order-placing-channel."+this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderPlacedEvent",
      async (e) => {
        const order = JSON.parse(e.order);
        this.play();
        this.orders.push(order);
      }
    );

    window.Echo.channel("order-cancelled-channel."+this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );

    window.Echo.channel("ready-order-channel."+this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderReadyEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );
  },
  mounted() {
    this.getOrders();
    this.getSettingsData()
  },
  data() {
    return {
      orders: [],
      branch: this.$store.getters['auth/branch'],
      loading: false,
      settings: {},
      port: "",
      ip: "",
    };
  },
  methods: {
    ...mapActions("global", ["showError", "showSuccess"]),
    getSettingsData() {
      this.loading = true;
      getSettings()
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.settings = response.data;
            if (this.settings) {
              (this.port = this.settings.port), (this.ip = this.settings.ip);
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    play() {
      this.$refs.audio.play();
    },
    getOrders() {
      this.loading = true;
      getPendingOrders()
        .then((response) => {
          if (response.status) {
            this.orders = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error)
        });
    },
    cancel(id) {
      this.loading = true;
      cancelOrder({id: id})
        .then((response) => {
            if (response.status) {
              this.loading = false
                this.showSuccess(response.message)
            } else {
                this.loading = false;
                this.showError(response.message)
            }
        })
        .catch((error) => {
            this.loading = false;
            this.showError(error)
        });
    },
    async ready(id) {
      this.loading = true;
      try {
        const ready = await readyOrder({ id: id });

        if (ready.status) {
          this.loading = false;
          const print = await this.print(ready.data);

          if (print) {
            this.showSuccess(ready.message);
          } else {
            this.showError("Some error occurred while printing");
          }
        } else {
          this.showError(ready.message);
        }
      } catch (error) {
        console.log(error);
        this.showError(error);
      }
    },
    async print(data) {
      const postData = {
        data: data,
        config: {
          ip: this.ip,
          port: this.port
        }
      }
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify(postData)
        );
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>